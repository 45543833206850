.shadow-0 {
  box-shadow: $shadow-0;
}

.shadow-1 {
  box-shadow: $shadow-1;
}

.shadow-2 {
  box-shadow: $shadow-2;
}

.form-control {
  font-size: $small-2-font-size;
}

.btn-circle {
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  border-radius: 100% !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.list-group.mb-5 {
  >.list-group.mb-5 {
    >a:first-child {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    +a {
      border-top-right-radius: $border-radius !important;
      border-top-left-radius: $border-radius !important;
    }
  }
}

p[align="justify"],
div[align="justify"] {
  text-align: left !important;
}

.modal-open {
  overflow: auto;
}

.opacity-1 {
  opacity: 1 !important;
}

.small-2 {
  font-size: $small-2-font-size !important;
  line-height: $small-line-height !important;
}

blockquote {
  font-family: $font-family-serif;
  font-size: $font-size-lg;
  font-style: italic;
  line-height: 1.6;

  @media screen and (max-width: map-get($grid-breakpoints, md)) {
    font-size: $font-size-lg * 0.9;
    line-height: 1.6;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, lg) - 1) {
  .display-4 {
    font-size: $display4-size * 0.9;
  }

  .display-3 {
    font-size: $display3-size * 0.9;
  }

  h1,
  .h1 {
    font-size: $h1-font-size * 0.9;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
  .display-4 {
    font-size: $display4-size * 0.75;
  }

  .display-3 {
    font-size: $display3-size * 0.75;
  }

  h1,
  .h1 {
    font-size: $h1-font-size * 0.85;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, sm) - 1) {
  .display-4 {
    font-size: $display4-size * 0.6;
  }

  .display-3 {
    font-size: $display3-size * 0.6;
  }

  h1,
  .h1 {
    font-size: $h1-font-size * 0.8;
  }
}

html {
  margin: 0 !important;
}

hr {
  width: 100%;
}

img {
  max-width: 100%;
  height: auto !important;
}

label {
  font-size: $small-2-font-size;
}

body {
  -webkit-font-smoothing: antialiased;
  //letter-spacing: -0.005em;
  overflow-x: hidden;
  line-height: 1.6em;
  transition: opacity 1s ease 0.5s;
}

a {
  transition: 0.4s ease;
  text-decoration: none !important;
}


section#quickLinks {
  z-index: 10;
  border-color: rgba($white, 0.1) !important;

  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {}
}

[data-brand*="fa-yelp"] {
  background-color: #af0606;
}

[data-brand*="fa-instagram"]:not([class*="bg-"]) {
  background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

[data-brand*="fa-facebook"] {
  background-color: #3b5998;
}

[data-brand*="fa-twitter"] {
  background-color: #1da1f2;
}

[data-brand*="fa-youtube"] {
  background-color: #ff0000;
}

[data-brand*="fa-linkedin"] {
  background-color: #0077b5;
}

[data-brand*="fa-google"] {
  background-color: #4285f4;
}

[data-brand*="fa-google-plus"] {
  background-color: #dd4b39;
}

.social-media>a:not([href]) {
  display: none !important
}

.form-control {
  //box-shadow: none !important;
}

.form {
  .small {
    color: $very-light;
  }
}

.owl-carousel {

  .owl-stage-outer {
    z-index: 1;
  }

  .owl-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 !important;
  }

  &:hover {

    .owl-prev,
    .owl-next {
      opacity: 0.5;
    }
  }

  .owl-prev,
  .owl-next {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    opacity: 0;
    transition: 0.4s ease;

    &[class*="-next"] {
      right: 0;
      left: auto;
    }

    &:hover {
      opacity: 1;
    }

    &.always-on {
      opacity: 1;
    }
  }

  .owl-dots {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    bottom: 0;
    text-align: left;
    opacity: 0;
    transition: 0.4s ease;

    &.outside-bottom {
      bottom: auto;
      top: 100%;
    }

    &.always-on {
      opacity: 1;
    }

    .owl-dot {
      span {
        background: rgba($white, 0.5);
        height: 8px;
        width: 8px;
      }

      &.dark span {
        background: rgba(map-get($theme-colors, dark), 0.15);
        height: 8px;
        width: 8px;
      }

      &:hover span {
        background: rgba($white, 0.75);
      }

      &.active span {
        background: $white;
      }

      &.dark:hover span {
        background: rgba(map-get($theme-colors, dark), 0.35);
      }

      &.dark.active span {
        background: rgba(map-get($theme-colors, dark), 0.5);
      }
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      opacity: 1 !important;
    }
  }

  &:hover {
    .owl-dots {
      opacity: 1;
    }
  }
}

.section-contact-form,
.section-blog {
  .owl-stage-outer {
    .owl-item {
      opacity: 0;
      visibility: hidden;
      transition: 0.2s ease;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

[class*="btn"]:not(.no-fx),
.hover-fx {
  transition: 0.3s ease !important;

  &:not([class*="owl"]):hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: $shadow-2;

    .panel-image-overlay {
      opacity: 0.3 !important;
    }
  }

  &[class*="owl"]:hover {
    margin-top: -2px !important;
    box-shadow: $shadow-2;
  }
}

[class*="btn"] {
  white-space: normal;
  //text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  padding: $spacer*0.65 $spacer*2;
  //letter-spacing: 0.04em;
}

a:not(.nav-link):not(.dropdown-item) {
  transition: 0.3s ease;

  &:hover {
    //transform: translate3d(0,-2px,0);
  }
}

.panel-image-overlay {
  transition: 0.3s ease;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
}

.panel-image-overlay.gradient.bottom-right {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 1)));
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  /* IE10 preview */
  background: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=1);
  /* IE6-8 fallback on horizontal gradient */
}

.panel-image-overlay.gradient.bottom-left {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* IE10 preview */
  background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);
  /* IE6-8 fallback on horizontal gradient */
}


.panel-image-overlay.gradient.top {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
  /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* IE10 preview */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
  /* IE6-8 */
}

footer {
  .navbar-brand {
    .company-name {
      .h4 {
        font-size: $h4-font-size * 0.8;
      }

      .h5 {
        font-size: $h5-font-size * 0.8;
      }

      .h6 {
        font-size: $h6-font-size * 0.9;
      }

      div {
        color: $white;
      }
    }
  }
}

#scrollTop:not(.appear) {
  opacity: 0;
  visibility: hidden;
  margin-bottom: 1rem !important;
}

.container-fluid.container-max-width {
  max-width: map-get($container-max-widths, xl);
}

header {
  z-index: 9;
  top: 0;

  .navbar-brand {
    .company-name {
      color: $white !important;
      letter-spacing: -0.02em !important;
      font-weight: 400 !important;

      .h4 {
        line-height: 1em;
        font-weight: 400 !important;
      }
    }
  }

  &:not(.sticky-header) {
    box-shadow: none !important;
    border: none !important;

    @media screen and (min-width: 1200px) {
      margin-top: 41px;
    }

    &>.container>.row {
      min-height: 2.5rem;
    }
  }

  &.hide {
    opacity: 0;
    transition: none;
  }

  &.sticky-header {
    transform: translate3d(0, -3.125rem, 0);
    position: fixed !important;
    background: map-get($theme-colors, dark) !important;
    transition: transform 0.4s ease, opacity 0.4s ease;
    padding-top: $spacer / 2 !important;
    padding-bottom: $spacer / 2 !important;

    .icons div>span>a,
    .icons div>a,
    .navbar-brand [id*="CompTitle"],
    .navbar-brand .logo {
      color: $white !important;
    }

    &.appear {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      .navbar-brand-container {
        margin-top: -100% !important;
      }
    }
  }

  img[id*="LogoControl"] {
    max-height: 50px;
  }

  @media screen and (max-width: map-get($grid-breakpoints, lg) - 1) {
    [id*="ControlText"]:not([id*="PhoneButton"]) {
      display: none;
    }
  }

  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
    .navbar-brand-container {
      border-top: 1px solid;
    }
  }
}

.modal-backdrop,
#pageOverlay {
  background: rgba($black, 0.5);
  position: fixed;
  z-index: 1048;
  top: -40px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease;
}

.modal-backdrop {
  background: none !important;
}

body.mobile-nav-show #fullPageWrapper #pageOverlay,
body.modal-open #fullPageWrapper #pageOverlay,
body.modal-open .modal-backdrop {
  opacity: 1;
  visibility: visible;
}

.modal {
  transition: opacity 0.4s ease, visibility 0.4s ease;
  opacity: 0;
  visibility: hidden;
  display: block !important;

  .modal-dialog {
    transition: 0.4s ease;
    opacity: 0;
    transform: translate3d(0, -2.5rem, 0) !important;
  }
}

.modal-open {
  .modal {
    opacity: 1;
    visibility: visible;

    .modal-dialog {
      opacity: 1;
      transform: translate3d(0, 0, 0) !important;
    }
  }
}

.search-field {
  .input-group {
    input {
      transition: 0.2s ease;
      border-color: $white;
      background: none;
      margin-left: 25%;
      color: $white;

      &::placeholder {
        color: rgba($white, 0.6);
      }

      &:hover,
      &:focus {
        margin-left: 0;
        background: $white;
        color: map-get($theme-colors, dark);

        &::placeholder {
          color: rgba(map-get($theme-colors, dark), 0.6);
        }
      }
    }

    .btn {
      padding-left: $spacer;
      padding-right: $spacer;
      background: none;
      border-color: $white;
      color: $white;
      border-left: 0;
    }

    input:hover+*>.btn,
    input:focus+*>.btn {
      background: map-get($theme-colors, primary);
      border-color: map-get($theme-colors, primary);
    }
  }

  &.dark {
    .input-group {
      input {
        color: map-get($theme-colors, dark) !important;
        border-color: rgba(map-get($theme-colors, dark), 0.5) !important;
        margin-left: 0;

        &::placeholder {
          color: rgba(map-get($theme-colors, dark), 0.6);
        }
      }

      .btn {
        color: rgba(map-get($theme-colors, dark), 0.5) !important;
        border-color: rgba(map-get($theme-colors, dark), 0.5);
      }

      input:focus+*>.btn {
        color: $white !important;
      }
    }
  }
  &.search-ui {
    transition: 0.4s ease;
    opacity: 0;
    visibility: hidden;
    .input-group {
      border: none !important;
      
      input {
        transition: 0.2s ease;
        border: none !important;
        border-bottom: $border-width solid $white !important;
        border-radius: 0 !important;
        background: none;
        margin-left: 0;
        color: $white;
        font-size: $h5-font-size;
        font-weight: 300;

        &::placeholder {
          color: rgba($white, 0.6);
        }

        &:hover,
        &:focus {
          margin-left: 0;
          background: none;
          color: $white;

          &::placeholder {
            color: $white;
          }
        }
      }

      .btn {
        padding-left: $spacer;
        padding-right: $spacer;
        border: none !important;
        border-bottom: $border-width solid $white !important;
        border-radius: 0 !important;
        background: none;
        color: $white;
        border-left: 0;
        display: flex;
        align-items: center;
      }

      input:hover+*>.btn,
      input:focus+*>.btn {
        border-bottom: $border-width solid $white !important;
        border-radius: 0 !important;
        background: none;
      }
    }

    &.dark {
      .input-group {
        input {
          color: map-get($theme-colors, dark) !important;
          border-color: rgba(map-get($theme-colors, dark), 0.5) !important;
          margin-left: 0;

          &::placeholder {
            color: rgba(map-get($theme-colors, dark), 0.6);
          }
        }

        .btn {
          color: rgba(map-get($theme-colors, dark), 0.5) !important;
          border-color: rgba(map-get($theme-colors, dark), 0.5);
        }

        input:focus+*>.btn {
          color: $white !important;
        }
      }
    }
  }
}

body.search-show {
  .search-field.search-ui {
    opacity: 1;
    visibility: visible;
  }
}

#fullPageWrapper,
#quickLinks,
header {
  transition: 0.3s ease;
}

body.mobile-nav-show {
  nav.navbar {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);

    @for $i from 1 to 100 {
      li:nth-child(#{$i}) {
        a {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
          transition-delay: ($i * 0.02s);
        }
      }
    }
  }
}

nav.navbar {
  overflow-y: auto;
  position: fixed;
  z-index: 1055;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  transition: 0.4s ease;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(25%, 0, 0);
  will-change: transform;

  > * {
    width: 80vw;
    max-width: 400px;
  }

  #navbarSupportedContent {
    display: block !important;
    height: auto !important;
  }

  ul {
    width: auto !important;
    margin: 0 !important;
    border: none !important;
    background-color: transparent !important;

    li {
      a {
        font-size: $font-size-base * 0.9;
        padding-top: $spacer/2 !important;
        padding-bottom: $spacer/2 !important;
        padding-left: $spacer * 1.5 !important;
        color: map-get($theme-colors, dark) !important;
        background-color: transparent !important;
        transition: 0.3s ease;
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, 1rem, 0);

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          top: 5%;
          bottom: 5%;
          left: 10%;
          right: 10%;
          transition: 0.3s ease;
          border-radius: $border-radius;
          background-color: rgba(map-get($theme-colors, dark), 0.1) !important;
          opacity: 0;
        }

        body[user-agent*="Mobile"] &:hover:before {
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          opacity: 1;
        }

        &:not(.has-submenu) {
          padding-right: $spacer * 1.5 !important;
        }
      }

      a.highlighted {
        background-color: transparent !important;
      }
    }

    ul {
      margin-left: $spacer !important;
      margin-bottom: 1rem;

      li {
        a {
          font-size: $font-size-base * 0.8;
          font-weight: 400;
        }
      }
    }
  }
}

.sub-arrow {
  border-radius: $border-radius !important;
  border: none !important;
  transition: transform 0.2s ease;
  margin-right: 0 !important;
  margin-left: 0 !important;

  &:before {
    content: '\f105' !important;
    font-family: 'Font Awesome 5 Pro';
    font-weight: lighter;
    margin-right: 0 !important;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;
  }

  svg {
    margin-right: 0 !important;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

[aria-expanded="true"] {
  &>.sub-arrow {
    transform: rotate(90deg);
  }
}


.section-testimonials {
  .owl-carousel {
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      min-height: 1px !important;
    }
    .owl-stage-outer {
      overflow: visible;
      
      .owl-stage {
        .owl-item {
          transition: 0.2s ease;
          
          &.active {
            opacity: 1;
          }
          
          .testimonial-author {
            justify-content: center;
            text-align: left !important;
          }
          
          .card {
            border: none;
            background: transparent;

            .card-body {
              padding: 0;
              blockquote {
                max-width: 768px;
                margin-left: auto !important;
                margin-right: auto !important;
                font-size: $h4-font-size;
                @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
                  font-size: $h5-font-size;
                }
              }
            }
          }
          
          .rounded-circle {
            font-size: 19px;
          }
          
          .testimonial-author {
            p {
              font-size: $font-size-sm;
              &.text-muted {
                color: rgba($white, 0.75) !important;
              }
            }
          }
        }
      }
    }
  }
  }
  
  #addressMap {
    .address-map {
      min-height: 500px;
      
      .google-maps {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
      }
      
    iframe {
      //height: 800px;
      //margin: -200px 0;
      min-height: 500px;
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {

      &,
      iframe {
        min-height: 300px;
      }
    }
  }

  .address-list-div {
    .container {

      >.row {
        align-items: center;

        .list-group {
          //box-shadow: $shadow-1;
          border: none !important;
          padding: $card-spacer-x !important;
          background-color: $white;
          border-radius: $border-radius;
          z-index: 2;

          >p:not(.h3) {
            font-size: 0.9rem;
            line-height: 1.4;
          }

          .list-group-item {
            background-color: transparent;
            border-color: transparent;
            width: auto !important;
            cursor: default;
            transition: background-color 0.3s ease, border-color 0.3s ease;
            border-radius: $border-radius;

            &:hover {
              background-color: rgba(map-get($theme-colors, secondary), 0.1);
            }

            &:active {
              background-color: rgba(map-get($theme-colors, secondary), 0.2);
            }

            &:first-child {
              padding: 0;

              p {
                font-size: $h3-font-size !important;
                line-height: $headings-line-height !important;
                font-family: $headings-font-family !important;
                margin-bottom: $headings-margin-bottom*2 !important;
              }
            }

            &:not(:first-child) {
              margin-left: -$card-spacer-x/2 !important;
              margin-right: -$card-spacer-x/2 !important;
            }

            span {
              transition: color 0.3s ease 0 !important;

              &.address-suite {
                font-weight: 300;
                font-size: $font-size-base;

                span:last-child {
                  display: none;
                }
              }

              &.city-state-zip {
                font-size: $font-size-sm;
              }
            }

            a {
              opacity: 0;
              visibility: hidden;
              transition: 0.3s ease 0 !important;
              color: map-get($theme-colors, secondary) !important;
            }

            &.active {
              background-color: map-get($theme-colors, primary);

              span {
                color: $white;
              }

              a {
                opacity: 1;
                visibility: visible;
                color: map-get($theme-colors, light) !important;
              }
            }
          }
        }
      }
    }
  }
}


.quick-contact-address {
  .city-state-zip {
    margin-bottom: 0.25rem;
  }

  .address-suite {
    span:last-child {
      span:last-child {
        display: none;
      }
    }
  }

  .phone-number>span:not(:empty):before,
  .fax-number>span:not(:empty):before {
    width: 2em;
    display: inline-block;
  }

  .phone-number>span:not(:empty):before {
    content: 'ph'
  }

  .fax-number>span:not(:empty):before {
    content: 'fax'
  }

}

html {
  .background-image-holder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    background: #252525;
    box-sizing: content-box;
  }

  .parallax {
    overflow: hidden;
    position: relative;
  }

  .parallax .background-image-holder {
    transition: none !important;
  }

  .parallax>.background-image-holder,
  .parallax .slides li>.background-image-holder {
    height: 100%;
    transition: opacity 0.3s ease !important;
  }

  @media all and (max-width: map-get($grid-breakpoints, md) - 1) {

    .parallax>.background-image-holder,
    .parallax .slides li>.background-image-holder {
      transition: transform 0.016s linear !important;
    }

    .background-image-holder,
    .slides li>.background-image-holder {
      top: 0 !important;
      transform: none !important;
      padding: 0;
    }
  }

  &:not([data-browser-type*="InternetExplorer"]) {

    .parallax>.background-image-holder {
      min-height: 100vh;
    }

    .parallax>.background-image-holder,
    .parallax .slides li>.background-image-holder {
      top: -50vh;
    }

    #homeOwlCarousel .background-image-holder,
    [id*="interiorBanner"] .background-image-holder {
      min-height: 1px;

      @media screen and (max-width: map-get($grid-breakpoints, lg) - 1) {
        height: auto;
      }
    }

    .parallax:first-child .slides li>.background-image-holder,
    .parallax:first-child .background-image-holder {
      top: 0;
    }

    .main-container>a:first-child+.parallax .background-image-holder {
      top: 0;
    }
  }

  &[data-user-agent*="Edge"],
  &[data-user-agent*="iPad"],
  &[data-user-agent*="iPhone"],
  &[data-user-agent*="Android"] {
    .background-image-holder {
      min-height: 1px;
    }

    .parallax>.background-image-holder,
    .parallax .slides li>.background-image-holder {
      top: 0;
    }
  }

  &[data-browser-type*="InternetExplorer"] {
    .modal {
      .modal-dialog-centered {
        min-height: 100% !important;
        margin-top: 0;
        margin-bottom: 0;

        .modal-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
  }
}

#homeOwlCarousel {
  position: relative;

  .owl-item {
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      [style*="720px"] {
        height: 600px !important;
      }
    }

    .tagline {
      opacity: 0;
      //transform: translate3d(0, -2.5rem, 0);
      transition: 0.4s ease;
    }

    &.translating {
      .panel-image {
        //animation: kenBurns 14s ease alternate infinite;
      }
    }

    &.active {
      .tagline {
        opacity: 1;
        //transform: translate3d(0, 0, 0);
        transition-delay: 0.75s;
      }

      .panel-image {
        //animation: kenBurns 14s ease alternate infinite;
      }
    }
  }
}

@keyframes kenBurns {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.cta-link {
  background-color: map-get($theme-colors, primary);
  color: $white !important;
  display: block;
  margin: 0;
  padding: 3rem 0;
  text-align: center;
  font-size: $h3-font-size;
  font-weight: 300;

  &:hover {
    background-color: lighten(map-get($theme-colors, primary), 5%);
  }
}

#procedures {
  overflow: hidden;

  .owl-stage-outer {
    overflow: visible;

    .owl-item {
      transition: opacity 0.4s ease;
      opacity: 0.25;

      &.active {
        opacity: 1;
      }
    }
  }
}

.phone-dropdown-menu,
.address-dropdown-menu {
  transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
  display: block;
  opacity: 0;
  visibility: hidden;
  padding: $spacer * 0.5;
  border: none;
  background: transparent;
  margin-top: -1.5rem !important;
  max-width: 100% !important;
  min-width: 16rem !important;
  margin-right: -5px;
  font-family: $font-family-sans-serif !important;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 5%;
    bottom: 25%;
    left: 25%;
    right: 25%;
    transition: 0.3s ease;
    border-radius: $border-radius;
    background-color: $drop-down-background !important;
    box-shadow: $shadow-1;
    opacity: 0;
  }

  &.show {
    opacity: 1;
    visibility: visible;

    &:before {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
    }

    ul {
      @for $i from 1 to 10 {
        a:nth-child(#{$i}) {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
          transition-delay: 0.2 + ($i * 0.025s);
        }
      }
    }
  }


  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
    left: 50% !important;
    transform: translate3d(-50%, 0, 0) !important;
    top: 2rem !important;
    width: 90%;
    max-width: 300px !important;
  }

  ul {
    padding: 0;
    margin: 0;

    a {
      font-size: $font-size-base * 0.8;
      line-height: 1.4em;
      transition: 0.3s ease;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 1rem, 0);
      display: block;
      span {
        color: $drop-down-color !important;
      }

      .row {
        padding: $spacer * 0.5 $spacer * 0.25 $spacer * 0.5 $spacer;
        position: relative;

        .font-weight-bold {
          font-weight: $drop-down-info-font-weight !important;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          top: 5%;
          bottom: 5%;
          left: 5%;
          right: 5%;
          transition: 0.3s ease;
          border-radius: $border-radius;
          background-color: $drop-down-hover-background !important;
          opacity: 0;
        }
      }

      &:hover {
        span {
          color: $drop-down-hover-color !important;
        }
        .row {
          &:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
          }
        }
      }
    }
  }
}

.quick-contact-address {
  .office-location {
    font-size: $font-size-base * 0.9;
    line-height: 1.4em;

    .section-title {
      display: none;
    }
  }
}

.section-contact-form {
  .qc-form {
    &>[class*="col"] {
      padding: 0;
    }

    .card {
      background: none;
      border: none !important;

      .card-body {
        padding: $card-spacer-x !important;

        [id*="HeaderInfoDiv"] {
          font-size: 0.9rem;
          line-height: $small-line-height;
        }

        .btn {
          width: 100%;
        }
      }
    }
  }

  .address-map {
    display: block;

    [class*="col"] {
      display: block;
      width: 100%;
      padding: 0;
    }
  }

  @media all and (max-width: map-get($grid-breakpoints, md) - 1) {
    & {
      text-align: center;

      .form-group {
        text-align: left;
      }

      .panel-image {
        position: relative;
        min-height: 50vw !important;

        .panel-image-overlay {
          opacity: 0.1 !important;
        }
      }

      .card {
        box-shadow: none !important;
      }
    }
  }
}

#fullPageWrapper {
  background: #fff;
  //max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

// Apointment Request Form


.list-group-item-action {
  overflow: hidden;

  [type="radio"],
  [type="checkbox"] {
    display: none;

    &~label {
      display: block;
      margin: 0;
      padding: 0.5rem 1rem;
      cursor: pointer;
      transition: padding 0.2s ease;
    }

    &:checked~label {
      background: map-get($theme-colors, primary);
      color: $white;
      padding-left: 1.5rem;
    }
  }
}

[data-page-type="carecredit"] header,
[data-page-type="carecredit"] #quickLinks {
  background-color: map-get($theme-colors, dark) !important;
}

.faq-list {
  .list-group-item {
    background-color: transparent;
  }
}